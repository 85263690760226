import React from 'react'
import PropTypes from 'prop-types'
import PredictionSeparator from '../matches/PredictionSeparator'
import CountryCard from './CountryCard'
import PotentialWinnings from './PotentialWinnings'
import constants from '../data/constants'

function PotentialChampions({ options, selected, country_predictions = {} }) {
  const potentialChampions = options
    .filter((o) => !o.isEliminated)
    .filter((o) => Object.values(country_predictions).includes(o.name))

  const eliminated = options
    .filter((o) => !!o.isEliminated)
    .filter((o) => Object.values(country_predictions).includes(o.name))

  const countrySelectedCount = Object.keys(country_predictions).reduce(
    (obj, participant) => {
      let newObj = Object.assign(obj)
      let country = country_predictions[participant]
      newObj[country] = obj[country]
        ? obj[country].concat(participant)
        : [participant]
      return newObj
    },
    {}
  )

  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <PredictionSeparator sectionTitle={'Potential Champions'} />
      {!potentialChampions.length && (
        <div
          style={{
            height: '48px',
            paddingLeft: '12px',
            display: 'flex',
            flex: 'row',
            alignItems: 'center',
          }}
        >
          <strong style={{ color: constants.colors.red }}>
            Nobody chose the winning country.
          </strong>
        </div>
      )}

      {potentialChampions.map((country) => (
        <div key={country.name}>
          <CountryCard
            country={country}
            isSelected={country.name === selected}
            count={countrySelectedCount[country.name].length || 0}
          />
          <PotentialWinnings
            activeParticipants={countrySelectedCount[country.name]}
            isSelected={country.name === selected}
            displayAmount
          />
        </div>
      ))}

      <PredictionSeparator sectionTitle={'Eliminated'} />
      {!eliminated.length && (
        <strong>Nobody's winner has been eliminated yet.</strong>
      )}

      {eliminated.map((country) => (
        <div key={country.name}>
          <CountryCard
            country={country}
            isSelected={country.name === selected}
            count={countrySelectedCount[country.name].length || 0}
          />
          <PotentialWinnings
            activeParticipants={countrySelectedCount[country.name]}
            isSelected={country.name === selected}
          />
        </div>
      ))}
    </div>
  )
}

PotentialChampions.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.string,
  country_predictions: PropTypes.object.isRequired,
}

export default PotentialChampions
