import React from 'react'
import PropTypes from 'prop-types'
import GameRules from './table/MatchPredictionRules'
import PlayerCard from './table/PlayerCard'
import TableGuidance from './table/TableGuidance'
import participants from './data/participants'
import stages from './data/stages'
import { sortAlphabetically } from './data/functions'

function calculateTable({ groups = {}, matches }) {
  if (!matches) return []

  let position = 0
  let points = -1

  const completedGroups = Object.values(groups)
    .filter((group) => !!group.result)
    .sort((a, b) => a.group - b.group)

  const completedMatches = Object.values(matches)
    .filter((match) => !!match.result)
    .sort((a, b) => a.id - b.id)

  let participantsWithPredictions = participants
    // add predictions
    .map((participant) => ({
      ...participant,
      predictions: completedGroups.concat(completedMatches).map((match) => ({
        match,
        prediction:
          !!match.predictions && match.predictions[participant.name]
            ? match.predictions[participant.name]
            : null,
        points:
          !!match.predictions &&
          !!match.predictions[participant.name] &&
          match.predictions[participant.name] === match.result
            ? Object.values(stages).find((stage) => stage.type === match.stage)
                .points
            : 0,
      })),
    }))
    // add total score
    .map((participant) => ({
      ...participant,
      score: participant.predictions.reduce(
        (acc, match) => acc + match.points,
        0
      ),
    }))
    // sort for position
    .sort((a, b) => sortAlphabetically(a.name, b.name))
    .sort((a, b) => b.score - a.score)
    // add position
    .map((participant, index) => {
      if (points !== participant.score) {
        position = index + 1
      }
      points = participant.score
      return {
        ...participant,
        position,
      }
    })

  return participantsWithPredictions
}

function calculatePointsLeft(matches) {
  if (!matches) return 0

  // create an array of matches mapped to the match points from '/constants/stages'
  let stageArray = []
  Object.values(stages).forEach((stage) => {
    let stagePoints = Array(stage.predictions).fill(stage.points)
    stageArray = stageArray.concat(stagePoints)
  })

  // filter out all completed matches, leaving the unplayed matches
  let remainingMatches = stageArray.slice(
    matches.filter((match) => !!match.result).length
  )

  // sum the points for the remaining matches
  return remainingMatches.reduce((acc, points) => acc + points, 0)
}

function calculateLastPersonThatCanWin({ table, index, pointsLeft }) {
  const isLastPlayer = table.length - 1 === index
  if (isLastPlayer) return false
  const playerCanBeFirst = table[index].score + pointsLeft >= table[0].score
  const nextPlayerCannotWin =
    table[index + 1].score + pointsLeft < table[0].score
  return playerCanBeFirst && nextPlayerCannotWin
}

function Table({ groups, matches, countries, country_predictions }) {
  const table = calculateTable({
    groups,
    matches: matches.filter((m) => m.stage !== stages.group.type),
  })
  const pointsLeft = calculatePointsLeft(
    Object.values(groups).concat(
      matches.filter((m) => m.stage !== stages.group.type)
    )
  )

  return (
    <main style={{ padding: '12px' }}>
      <TableGuidance />

      <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
        {table.map((player, index) => (
          <PlayerCard
            key={player.name}
            player={player}
            countries={countries}
            country_predictions={country_predictions}
            isLastPersonThatCanWin={calculateLastPersonThatCanWin({
              table,
              index,
              pointsLeft,
            })}
          />
        ))}
      </div>

      <GameRules />
    </main>
  )
}

Table.propTypes = {
  groups: PropTypes.object,
  matches: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
}

export default Table
