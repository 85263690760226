import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../data/constants'
import {
  getParticipantNameFromNumber,
  sortAlphabetically,
} from '../../../../utilities/lib'
import countries from '../../data/countries'

function GroupPrediction({ group, number, chooseOption }) {
  const selected =
    !!group.predictions &&
    number &&
    group.predictions[getParticipantNameFromNumber(number)]
      ? group.predictions[getParticipantNameFromNumber(number)]
      : null

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flex: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {group.countries.sort(sortAlphabetically).map((option) => (
        <button
          key={option}
          type="button"
          style={{
            width: `calc(49%)`,
            minWidth: `calc(49%)`,
            marginBottom: '4px',
            textAlign: 'center',
            padding: '8px',
            backgroundColor:
              selected === option ? constants.colors.teal : 'transparent',
            cursor: 'pointer',
            borderRadius: '8px',
            border:
              group.result === option
                ? `2px solid ${constants.colors.red}`
                : '0px',
          }}
          onClick={() => chooseOption(option)}
        >
          <img
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '24px',
              border: '1px solid lightgray',
            }}
            alt={option}
            src={countries.find((c) => c.name === option).flag}
          />

          <br />
          <strong
            style={{
              fontSize: '16px',
              color: selected === option.name ? constants.colors.cream : '',
            }}
          >
            {option}
          </strong>
        </button>
      ))}
    </div>
  )
}

GroupPrediction.propTypes = {
  group: PropTypes.object,
  database: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
  chooseOption: PropTypes.func.isRequired,
}

export default GroupPrediction
