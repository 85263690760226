import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PredictionHeader from '../PredictionHeader'
import GroupPrediction from './GroupPrediction'
import Predictions from '../Predictions'
import constants from '../../data/constants'
import stages from '../../data/stages'
import { getParticipantNameFromNumber } from '../../../../utilities/lib'
import chooseGroupResult from '../../../../actions/chooseGroupResult'
import toggleGroupWinnerStatus from '../../../../actions/toggleGroupWinnerStatus'

function GroupDetails({ group, database, number }) {
  const [isEditing, setIsEditing] = useState(false)

  const isAdmin = constants.admin.includes(number)
  const hasStarted = new Date(group.date) < Date.now()

  const chooseOption = (option) => {
    if (isEditing) {
      toggleGroupWinnerStatus({ group, database, option })
    } else {
      const hasStarted = new Date(group.date) < Date.now()
      if (hasStarted) return

      const participantName = getParticipantNameFromNumber(number)
      chooseGroupResult({
        groupID: group.group,
        database,
        participantName,
        option,
      })
    }
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '600px',
        marginBottom: '12px',
        backgroundColor: constants.colors.cream,
        padding: '12px',
        borderRadius: '8px',
      }}
    >
      <PredictionHeader
        predictionID={'Groups'}
        stage={stages.group.name}
        group={group.group}
        date={group.date}
        isAdmin={isAdmin}
        isEditing={isEditing}
        toggleEditing={() => setIsEditing(!isEditing)}
      />

      <GroupPrediction
        group={group}
        number={number}
        chooseOption={chooseOption}
      />

      <Predictions
        predictions={group.predictions || {}}
        result={group.result}
        number={number}
        hasStarted={hasStarted}
      />
    </div>
  )
}

GroupDetails.propTypes = {
  group: PropTypes.object.isRequired,
  database: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
}

export default GroupDetails
