import React from 'react'
import PropTypes from 'prop-types'
import constants from '../data/constants'

function PredictionSeparator({ sectionTitle, children }) {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: constants.colors.blue,
        padding: '8px 12px',
        marginBottom: '4px',
      }}
    >
      <strong style={{ color: constants.colors.cream }}>{sectionTitle}</strong>
      {children}
    </div>
  )
}

PredictionSeparator.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
}

export default PredictionSeparator
