const constants = {
  version: 'v2024.06.27',
  admin: ['+6587427184'],
  TOURNAMENT_NAME: 'UEFA Euro 2024',
  LOGO_URL:
    'https://upload.wikimedia.org/wikipedia/en/thumb/2/26/UEFA_Euro_2024_Logo.svg/440px-UEFA_Euro_2024_Logo.svg.png',
  COUNTRIES_URL: 'euro2024/countries/',
  COUNTRY_PREDICTIONS_URL: 'euro2024/country_predictions/',
  MATCHES_URL: 'euro2024/matches/',
  GROUPS_URL: 'euro2024/groups/',
  START_DATE: '2024-06-14T19:00:00.000Z',
  buyIn: {
    match: 10,
    country: 5,
  },
  colors: {
    red: '#e63946',
    cream: '#f1faee',
    teal: '#a8dadc',
    blue: '#457b9d',
    dark: '#1d3557',
    white: '#ffffff',
  },
}

export default constants
