import React, { Component } from 'react'
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import Header from './current/Header'
import Matches from './current/Matches'
import Table from './current/Table'
import Countries from './current/Countries'
import LogOut from './LogOut'
import constants from './current/data/constants'

const config = {
  apiKey: 'AIzaSyCdnVAAihT9Q2cVyxbURwgB2vFKm68xQkE',
  authDomain: 'football-prediction-game-b35fd.firebaseapp.com',
  projectId: 'football-prediction-game-b35fd',
  databaseURL:
    'https://football-prediction-game-b35fd-default-rtdb.asia-southeast1.firebasedatabase.app/',
  storageBucket: 'football-prediction-game-b35fd.appspot.com',
  messagingSenderId: '135136030678',
  appId: '1:135136030678:web:2f62a5281bc7e0c118fe77',
}

firebase.initializeApp(config)
const database = firebase.database()

class Root extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      number: null,
      hidden: true,
      fplData: {},
    }
    this.removeNumber = this.removeNumber.bind(this)
    this.unregisterAuthObserver = null
    this.uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          window.localStorage.setItem(
            'number',
            authResult.user.phoneNumber || authResult.user.email
          )
          this.setState({
            number: authResult.user.phoneNumber || authResult.user.email,
          })
          // console.log(authResult, redirectUrl, this.state);
          return false
        }.bind(this),
        // uiShown: function() {
        //   // The widget is rendered.
        //   // Hide the loader.
        //   document.getElementById('loader').style.display = 'none';
        // }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      // signInSuccessUrl: '<url-to-redirect-to-on-success>',
      signInOptions: [
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID },
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'SG',
        },
      ],
    }
  }

  componentDidMount() {
    // return firebase.auth().signOut()
    window.scrollTo(0, 0)
    database.ref('/').on('value', (snapshot) => {
      let data = snapshot.val()
      // console.log('snapshot', data)
      this.setState({ data })

      // for (var key in polls) {
      // let poll = polls[key]
      // poll.users['+61430985344'] = {answer: 0, answered: true}
      // polls[key] = poll
      // }
    })

    let number = window.localStorage.getItem('number')
    this.setState({ hidden: !!number })
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      // console.log(user)
      if (!!user) {
        this.setState({ number: user.phoneNumber || user.email })
        window.localStorage.setItem('number', user.phoneNumber || user.email)

        // database.ref("/users").once("value", snap => {
        //   let updatedUser = Object.assign({}, snap.val()[user.phoneNumber]);
        //   updatedUser.updated = "v1.1";
        //   database
        //     .ref("/")
        //     .child("users/" + user.phoneNumber)
        //     .update(updatedUser);
        // });
      }
    })
  }

  componentWillUnmount() {
    // this.unregisterAuthObserver()
  }

  removeNumber() {
    this.setState({ number: null, hidden: false })
  }

  render() {
    const currentTournamentData = this.state.data.euro2024

    return (
      <main>
        {this.state.number ? (
          !!currentTournamentData ? (
            Object.values(currentTournamentData.participants).find((p) =>
              p.numbers.includes(this.state.number)
            ) ? (
              <Router>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/predictions" />}
                />
                <Route path="/" render={(props) => <Header {...props} />} />
                <Switch>
                  <Route
                    path="/predictions"
                    render={() => (
                      <Matches
                        groups={currentTournamentData.groups}
                        matches={currentTournamentData.matches}
                        database={database}
                        number={this.state.number}
                        countries={currentTournamentData.countries}
                      />
                    )}
                  />

                  <Route
                    path="/table"
                    render={() => (
                      <Table
                        groups={currentTournamentData.groups}
                        matches={currentTournamentData.matches}
                        country_predictions={
                          currentTournamentData.country_predictions
                        }
                        countries={currentTournamentData.countries}
                        number={this.state.number}
                      />
                    )}
                  />

                  <Route
                    path="/countries"
                    render={() => (
                      <Countries
                        countries={currentTournamentData.countries}
                        country_predictions={
                          currentTournamentData.country_predictions
                        }
                        database={database}
                        number={this.state.number}
                      />
                    )}
                  />
                  <Route
                    path="/logout"
                    render={() => (
                      <LogOut
                        auth={firebase.auth()}
                        removeNumber={this.removeNumber}
                      />
                    )}
                  />
                </Switch>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '1rem',
                    marginBottom: '16px',
                  }}
                >
                  <strong>{constants.version}</strong>
                </div>
              </Router>
            ) : (
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1>
                  Thanks for visiting my website.
                  <br />
                  It's currently for my personal use.
                  <br /> You can find out more about me{' '}
                  <a href="https://www.linkedin.com/in/jonathanlouisng/">
                    here
                  </a>
                  .
                </h1>
              </div>
            )
          ) : (
            <div
              style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <i
                className="fa fa-futbol-o fa-spin"
                style={{ fontSize: '2em' }}
              />
              <h1>Loading</h1>
            </div>
          )
        ) : (
          <div
            style={{
              width: this.state.hidden ? '0' : '100%',
              height: 'calc(100vh - 16px)',
              minWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Football Prediction Game</h2>
            <StyledFirebaseAuth
              uiConfig={this.uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </div>
        )}
      </main>
    )
  }
}

export default Root
