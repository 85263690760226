import React, { useState } from 'react'
import PropTypes from 'prop-types'
import constants from '../../data/constants'
import stages from '../../data/stages'
import countries from '../../data/countries'
import { sortAlphabetically } from '../../data/functions'

function updateField({ id, database, field, value }) {
  let update = {}
  update[field] = value

  database.ref(`${constants.MATCHES_URL}${id}`).update(update)
}

function EditMatch({ match, database }) {
  const [homeGoals, setHomeGoals] = useState(match.home_score || 0)
  const [awayGoals, setAwayGoals] = useState(match.away_score || 0)

  const updateTeam = (country, type) => {
    updateField({
      id: match.id,
      database,
      field: type === 'home' ? 'home_team' : 'away_team',
      value: country,
    })
  }

  const options =
    match.stage === stages.group.type
      ? [match.home_team, 'Draw', match.away_team]
      : [match.home_team, match.away_team]

  return (
    <>
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ width: `33.3333%`, textAlign: 'center', padding: '8px' }}>
          <img
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '24px',
              border: '1px solid lightgray',
            }}
            alt={match.home_team}
            src={
              countries.find((c) => c.name === match.home_team)
                ? countries.find((c) => c.name === match.home_team).flag
                : ''
            }
          />
        </div>

        <div
          style={{
            width: `33.3333%`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px',
          }}
        >
          <input
            type="number"
            value={homeGoals}
            onChange={(e) => {
              setHomeGoals(parseInt(e.target.value, 10))
              updateField({
                id: match.id,
                database,
                field: 'home_score',
                value: parseInt(e.target.value, 10),
              })
            }}
            style={{ width: '45%', padding: '8px', fontSize: '24px' }}
          />

          <div style={{ width: '10%', textAlign: 'center' }}>-</div>

          <input
            type="number"
            value={awayGoals}
            onChange={(e) => {
              setAwayGoals(parseInt(e.target.value, 10))
              updateField({
                id: match.id,
                database,
                field: 'away_score',
                value: parseInt(e.target.value, 10),
              })
            }}
            style={{ width: '45%', padding: '8px', fontSize: '24px' }}
          />
        </div>

        <div style={{ width: `33.3333%`, textAlign: 'center', padding: '8px' }}>
          <img
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '24px',
              border: '1px solid lightgray',
            }}
            alt={match.away_team}
            src={
              countries.find((c) => c.name === match.away_team)
                ? countries.find((c) => c.name === match.away_team).flag
                : ''
            }
          />
        </div>
      </div>

      {options.map((option) => (
        <button
          key={option}
          type="button"
          style={{
            width: `calc(100%/${options.length})`,
            textAlign: 'center',
            padding: '8px',
            backgroundColor:
              match.result === option ? constants.colors.teal : '',
            cursor: 'pointer',
            borderRadius: '8px',
            marginBottom: '8px',
          }}
          onClick={() =>
            updateField({
              id: match.id,
              database,
              field: 'result',
              value: match.result === option ? null : option,
            })
          }
        >
          <strong
            style={{
              fontSize: '16px',
              color: match.result === option ? constants.colors.cream : '',
            }}
          >
            {option}
          </strong>
        </button>
      ))}

      <div style={{ display: 'flex' }}>
        <div style={{ width: '33.3333%', textAlign: 'center' }}>
          <select
            value={match.home_team}
            onChange={(e) => updateTeam(e.target.value, 'home')}
          >
            <option value="" disabled>
              Change home country
            </option>
            {countries
              .sort((a, b) => sortAlphabetically(a.name, b.name))
              .map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
          </select>
        </div>
        <div style={{ width: '33.3333%' }} />
        <div style={{ width: '33.3333%', textAlign: 'center' }}>
          <select
            value={match.away_team}
            onChange={(e) => updateTeam(e.target.value, 'away')}
          >
            <option value="" disabled>
              Change away country
            </option>
            {countries
              .sort((a, b) => sortAlphabetically(a.name, b.name))
              .map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </>
  )
}

EditMatch.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number.isRequired,
    home_team: PropTypes.string.isRequired,
    away_team: PropTypes.string.isRequired,
    home_score: PropTypes.number,
    away_score: PropTypes.number,
    home_flag: PropTypes.string.isRequired,
    away_flag: PropTypes.string.isRequired,
    result: PropTypes.string,
  }).isRequired,
  database: PropTypes.object.isRequired,
}

export default EditMatch
