import constants from '../containers/current/data/constants'

const toggleGroupWinnerStatus = ({ group, database, option }) => {
  let groupToUpdate = {
    ...group,
    result: group.result === option ? null : option,
  }
  database
    .ref(constants.GROUPS_URL)
    .child(groupToUpdate.group)
    .update(groupToUpdate)
}

export default toggleGroupWinnerStatus
