import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function calculateTimeLeft(date) {
  let difference = new Date(date) - Date.now()
  if (difference < 0)
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }

  let hours = Math.floor(difference / 1000 / 60 / 60)

  let minutes =
    hours === 0
      ? Math.floor(difference / 1000 / 60)
      : Math.floor((difference / 1000 / 60) % (hours * 60))

  let seconds =
    hours * 60 * 60 + minutes * 60 === 0
      ? Math.floor(difference / 1000)
      : Math.floor((difference / 1000) % (hours * 60 * 60 + minutes * 60))

  return { hours, minutes, seconds }
}

function formatTime({ hours, minutes, seconds }) {
  return [
    hours,
    minutes < 10 ? `0${minutes}` : minutes,
    seconds < 10 ? `0${seconds}` : seconds,
  ]
}

function CountdownTimer({ date }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date))
  const hasMatchStarted = new Date(date) < Date.now()

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div style={{ width: '33.3333%', textAlign: 'right' }}>
      <i className="fa fa-clock-o" />
      &nbsp;
      {!hasMatchStarted ? (
        <span>
          {formatTime(timeLeft)[0]}:{formatTime(timeLeft)[1]}:
          {formatTime(timeLeft)[2]}
        </span>
      ) : (
        <span>Closed</span>
      )}
    </div>
  )
}

CountdownTimer.propTypes = {
  date: PropTypes.string.isRequired,
}

export default CountdownTimer
