const countries = [
  {
    name: 'Hungary',
    code: 'HUN',
    group: 'A',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Flag_of_Hungary.svg/250px-Flag_of_Hungary.svg.png',
  },
  {
    name: 'Switzerland',
    code: 'SUI',
    group: 'A',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Flag_of_Switzerland_%28Pantone%29.svg/250px-Flag_of_Switzerland_%28Pantone%29.svg.png',
  },
  {
    name: 'Germany',
    code: 'GER',
    group: 'A',
    flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/250px-Flag_of_Germany.svg.png',
  },
  {
    name: 'Scotland',
    code: 'SCO',
    group: 'A',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Flag_of_Scotland.svg/250px-Flag_of_Scotland.svg.png',
  },
  {
    name: 'Spain',
    code: 'ESP',
    group: 'B',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/250px-Bandera_de_Espa%C3%B1a.svg.png',
  },
  {
    name: 'Italy',
    code: 'ITA',
    group: 'B',
    flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/0/03/Flag_of_Italy.svg/250px-Flag_of_Italy.svg.png',
  },
  {
    name: 'Croatia',
    code: 'CRO',
    group: 'B',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Croatia.svg/250px-Flag_of_Croatia.svg.png',
  },
  {
    name: 'Albania',
    code: 'ALB',
    group: 'B',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Flag_of_Albania.svg/250px-Flag_of_Albania.svg.png',
  },
  {
    name: 'Denmark',
    code: 'DEN',
    group: 'C',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/240px-Flag_of_Denmark.svg.png',
  },
  {
    name: 'England',
    code: 'ENG',
    group: 'C',
    flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/be/Flag_of_England.svg/250px-Flag_of_England.svg.png',
  },
  {
    name: 'Slovenia',
    code: 'SVN',
    group: 'C',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/Flag_of_Slovenia.svg/250px-Flag_of_Slovenia.svg.png',
  },
  {
    name: 'Serbia',
    code: 'SRB',
    group: 'C',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Flag_of_Serbia.svg/250px-Flag_of_Serbia.svg.png',
  },
  {
    name: 'France',
    code: 'FRA',
    group: 'D',
    flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/250px-Flag_of_France.svg.png',
  },
  {
    name: 'Austria',
    code: 'AUT',
    group: 'D',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/250px-Flag_of_Austria.svg.png',
  },
  {
    name: 'Poland',
    code: 'POL',
    group: 'D',
    flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/250px-Flag_of_Poland.svg.png',
  },
  {
    name: 'Netherlands',
    code: 'NED',
    group: 'D',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/250px-Flag_of_the_Netherlands.svg.png',
  },
  {
    name: 'Romania',
    code: 'ROU',
    group: 'E',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Romania.svg/250px-Flag_of_Romania.svg.png',
  },
  {
    name: 'Ukraine',
    code: 'UKR',
    group: 'E',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/250px-Flag_of_Ukraine.svg.png',
  },
  {
    name: 'Slovakia',
    code: 'SVK',
    group: 'E',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Slovakia.svg/250px-Flag_of_Slovakia.svg.png',
  },
  {
    name: 'Belgium',
    code: 'BEL',
    group: 'E',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Belgium.svg/250px-Flag_of_Belgium.svg.png',
  },
  {
    name: 'Czechia',
    code: 'CZE',
    group: 'F',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/250px-Flag_of_the_Czech_Republic.svg.png',
  },
  {
    name: 'Portugal',
    code: 'POR',
    group: 'F',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/250px-Flag_of_Portugal.svg.png',
  },
  {
    name: 'Türkiye',
    code: 'TUR',
    group: 'F',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/250px-Flag_of_Turkey.svg.png',
  },
  {
    name: 'Georgia',
    code: 'GEO',
    group: 'F',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Georgia.svg/250px-Flag_of_Georgia.svg.png',
  },
]

export default countries
