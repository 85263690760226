import participants from '../containers/current/data/participants'

export const sortAlphabetically = (first, second) => {
  switch (true) {
    case first > second:
      return 1
    case first < second:
      return -1
    default:
      return -1
  }
}

export const getParticipantNameFromNumber = (number) => {
  return participants.find((p) => p.numbers.includes(number)).name
}
