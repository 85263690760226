import React from 'react'
import PropTypes from 'prop-types'
import constants from '../data/constants'
import stages from '../data/stages'

function PlayerHistory({ player, countries }) {
  const teamsAndDraw = countries.concat({
    name: 'Draw',
    flag: 'https://www.symbols.com/images/symbol/1/5528_dash.png',
  })

  return (
    <table
      style={{
        width: '100%',
        border: `1px solid ${constants.colors.teal}`,
        padding: '8px',
      }}
    >
      {Object.values(stages).map((stage) => (
        <tbody key={stage.type}>
          <tr>
            <td style={{ padding: '16px 8px 0px 8px' }}>
              <strong>{stage.name}</strong>
            </td>
          </tr>

          {player.predictions
            .filter((prediction) => prediction.match.stage === stage.type)
            .map((prediction, index) => (
              <tr
                key={prediction.match.id}
                style={{
                  backgroundColor: index % 2 ? '' : constants.colors.cream,
                }}
              >
                <td style={{ width: '50%', padding: '4px 8px' }}>
                  {/* Show the group name if the prediction is Group Winner, otherwise show the match participants */}
                  {prediction.match.countries
                    ? `Group ${prediction.match.group}`
                    : `${prediction.match.home_team} v ${prediction.match.away_team}`}
                </td>
                <td style={{ width: '35%', padding: '4px 8px' }}>
                  {!!prediction && !!prediction.prediction && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={
                          teamsAndDraw.filter(
                            (team) => team.name === prediction.prediction
                          )[0].flag
                        }
                        alt={prediction.prediction}
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '12px',
                          border: '1px solid lightgray',
                          marginRight: '8px',
                        }}
                      />{' '}
                      <strong>{prediction.prediction}</strong>
                    </div>
                  )}
                </td>
                <td
                  style={{
                    width: '15%',
                    padding: '4px 8px',
                    textAlign: 'center',
                  }}
                >
                  <strong>{prediction.points}</strong>
                </td>
              </tr>
            ))}
        </tbody>
      ))}
    </table>
  )
}

PlayerHistory.propTypes = {
  player: PropTypes.shape({
    match: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
    predictions: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number,
        home_team: PropTypes.string,
        away_team: PropTypes.string,
        prediction: PropTypes.string,
        points: PropTypes.number,
      })
    ),
  }),
  countries: PropTypes.array,
}

export default PlayerHistory
