import React, { useState } from 'react'
import PropTypes from 'prop-types'
import participants from '../data/participants'
import constants from '../data/constants'
import {
  getParticipantNameFromNumber,
  sortAlphabetically,
} from '../../../utilities/lib'

function predictionMatchesResult({ result, prediction }) {
  switch (true) {
    case !result:
      return false
    case prediction === result:
      return true
    default:
      return false
  }
}

function Predictions({ predictions, result, number, hasStarted }) {
  const [isVisible, setIsVisible] = useState(false)
  const displayPrediction = (player) => {
    switch (true) {
      case hasStarted:
        return predictions[player.name] || '-'
      default:
        return predictions[player.name] ? 'Selected' : ''
    }
  }

  return (
    <div
      style={{
        width: '100%',
        borderTop: `1px solid ${constants.colors.teal}`,
        paddingTop: '8px',
        textAlign: 'center',
        marginTop: '24px',
      }}
    >
      <button
        type="button"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsVisible(!isVisible)}
      >
        <strong>{isVisible ? 'Hide' : 'Show'} Predictions</strong>
      </button>

      {isVisible && (
        <table style={{ width: '100%', marginTop: '8px' }}>
          <tbody>
            {participants
              .sort((a, b) =>
                sortAlphabetically(a.name.toLowerCase(), b.name.toLowerCase())
              )
              .map((player, index) => (
                <tr
                  key={player.name}
                  style={{
                    backgroundColor:
                      index % 2
                        ? constants.colors.cream
                        : constants.colors.teal,
                  }}
                >
                  <td
                    style={{
                      width: '50%',
                      padding: '4px 8px',
                      textAlign: 'left',
                    }}
                  >
                    <strong
                      style={{
                        color:
                          player.name === getParticipantNameFromNumber(number)
                            ? constants.colors.red
                            : constants.colors.dark,
                      }}
                    >
                      {player.name}
                    </strong>
                  </td>
                  <td
                    style={{
                      width: '50%',
                      padding: '4px 8px',
                      textAlign: 'left',
                    }}
                  >
                    <strong
                      style={{
                        color: predictionMatchesResult({
                          result,
                          prediction: predictions[player.name],
                        })
                          ? constants.colors.red
                          : '',
                      }}
                    >
                      {displayPrediction(player)}
                    </strong>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

Predictions.propTypes = {
  predictions: PropTypes.object.isRequired,
  result: PropTypes.string,
  number: PropTypes.string.isRequired,
  hasStarted: PropTypes.bool.isRequired,
}

export default Predictions
