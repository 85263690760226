import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PlayerHistory from './PlayerHistory'
import constants from '../data/constants'

function PlayerCard({ player, countries, isLastPersonThatCanWin }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div style={{ marginBottom: '4px' }}>
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '8px',
          backgroundColor: isExpanded
            ? constants.colors.teal
            : constants.colors.cream,
          borderBottom: isLastPersonThatCanWin
            ? `4px solid ${constants.colors.red}`
            : '',
        }}
      >
        <div style={{ width: '15%', textAlign: 'center', fontSize: '24px' }}>
          <strong>{player.position}</strong>
        </div>
        <div style={{ width: '70%', fontSize: '24px' }}>
          <strong>{player.name}</strong>
        </div>
        <div style={{ width: '15%', textAlign: 'center', fontSize: '24px' }}>
          <strong>{player.score}</strong>
        </div>
      </div>
      {isExpanded && <PlayerHistory player={player} countries={countries} />}
    </div>
  )
}

PlayerCard.propTypes = {
  player: PropTypes.shape({
    position: PropTypes.number,
    name: PropTypes.string,
    score: PropTypes.number,
  }),
  countries: PropTypes.array,
  isLastPersonThatCanWin: PropTypes.bool,
}

export default PlayerCard
