import React from 'react'
import GroupDetails from './GroupDetails'

export default function Groups({ groups, database, number }) {
  return (
    <>
      {Object.values(groups)
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map((g) => (
          <GroupDetails
            key={g.group}
            group={g}
            database={database}
            number={number}
          />
        ))}
    </>
  )
}
