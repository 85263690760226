import React from 'react'
import PropTypes from 'prop-types'
import CountryCard from './CountryCard'

function CountrySelection({ options, chooseCountry, selected }) {
  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      {options.map((country) => (
        <button
          key={country.name}
          type="button"
          onClick={() => chooseCountry(country.name)}
          style={{ width: '100%', maxWidth: '600px', cursor: 'pointer' }}
        >
          <CountryCard
            country={country}
            isSelected={country.name === selected}
            count={0}
          />
        </button>
      ))}
    </div>
  )
}

CountrySelection.propTypes = {
  options: PropTypes.array,
  chooseCountry: PropTypes.func.isRequired,
  selected: PropTypes.string,
  country_predictions: PropTypes.object.isRequired,
}

export default CountrySelection
