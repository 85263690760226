import React from 'react'
import PropTypes from 'prop-types'
import constants from '../data/constants'

function CountryCard({ country, isSelected, count }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        marginBottom: '8px',
        backgroundColor: isSelected
          ? constants.colors.teal
          : country.isEliminated
          ? constants.colors.red
          : constants.colors.cream,
        borderRadius: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '24px',
            border: '1px solid lightgrey',
            marginRight: '16px',
          }}
          alt={country.name}
          src={country.flag}
        />
        <strong style={{ fontSize: '24px' }}>{country.name}</strong>
      </div>
      {!!count && (
        <strong style={{ fontSize: '24px', marginRight: '16px' }}>
          {count}
        </strong>
      )}
    </div>
  )
}

CountryCard.propTypes = {
  country: PropTypes.shape({
    name: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
  }),
  isSelected: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
}

export default CountryCard
