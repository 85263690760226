// matches = number of matches; predictions = number of predictions to be made
const stages = {
  group: {
    number: 1,
    type: 'group',
    name: 'Group',
    points: 1,
    matches: 36,
    predictions: 6,
  },
  roundOf16: {
    number: 2,
    type: 'roundOf16',
    name: 'Round of 16',
    points: 2,
    matches: 8,
    predictions: 8,
  },
  quarterfinals: {
    number: 3,
    type: 'quarterfinals',
    name: 'Quarter-finals',
    points: 3,
    matches: 4,
    predictions: 4,
  },
  semifinals: {
    number: 4,
    type: 'semifinals',
    name: 'Semi-finals',
    points: 4,
    matches: 2,
    predictions: 2,
  },
  // thirdplace: {
  //   number: 5,
  //   type: 'thirdplace',
  //   name: 'Third place play-off',
  //   points: 5,
  //   matches: 1,
  // },
  final: {
    number: 5,
    type: 'final',
    name: 'Final',
    points: 6,
    matches: 1,
    predictions: 1,
  },
}

export default stages
