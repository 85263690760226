import React from 'react'
import { NavLink } from 'react-router-dom'
import constants from './data/constants'

const sections = ['predictions', 'table', 'countries']

const Header = () => (
  <div>
    <h1
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '12px',

        fontSize: '36px',
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
    >
      <img
        src={constants.LOGO_URL}
        alt={constants.TOURNAMENT_NAME}
        style={{ height: '72px', marginRight: '12px' }}
      />
      <span style={{ color: constants.colors.dark }}>
        {constants.TOURNAMENT_NAME}
      </span>
    </h1>

    <div style={{ backgroundColor: constants.colors.dark }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '600px',
          margin: '12px auto',
        }}
      >
        {sections.map((sect) => (
          <NavLink
            key={sect}
            to={`/${sect}`}
            activeStyle={{
              backgroundColor: 'gold',
              color: constants.colors.dark,
            }}
            style={{
              width: `calc(100%/${sections.length})`,
              maxWidth: `calc(600px/${sections.length})`,
              fontWeight: 'bold',
              textAlign: 'center',
              color: 'white',
              textDecoration: 'none',
              padding: '8px',
            }}
          >
            {sect[0].toUpperCase().concat(sect.slice(1))}
          </NavLink>
        ))}
      </div>
    </div>
  </div>
)

export default Header
