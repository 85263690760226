import React from 'react'
import PropTypes from 'prop-types'
import MatchDetails from './matches/Matches/MatchDetails'
import PredictionGuidance from './matches/PredictionGuidance'
import PredictionSeparator from './matches/PredictionSeparator'
import FinishedMatches from './matches/FinishedMatches'
import Groups from './matches/Groups/Groups'
import stages from './data/stages'

const oneDay = 1000 * 60 * 60 * 24

function Matches({ groups, matches, database, number, countries }) {
  // sort by most recent first, then by match id
  let groupMatches = Object.values(
    matches.filter((match) => match.stage === stages.group.type)
  ).sort((a, b) => new Date(a.date) - new Date(b.date) || a.id - b.id)

  let allMatches = Object.values(
    matches.filter((match) => match.stage !== stages.group.type)
  ).sort((a, b) => new Date(a.date) - new Date(b.date) || a.id - b.id)

  // matches that finished more than one day ago
  let finishedMatches = allMatches.filter(
    (match) => new Date(match.date) < Date.now() - oneDay
  )

  // matches that finished less than one day ago
  let recentMatches = allMatches.filter(
    (match) =>
      new Date(match.date) > Date.now() - oneDay &&
      new Date(match.date) < Date.now()
  )

  // matches to be played in the next 24 hours
  let todayMatches = allMatches.filter(
    (match) =>
      new Date(match.date) > Date.now() &&
      new Date(match.date) < Date.now() + oneDay
  )

  // matches to be played more than 24 hours later
  let upcomingMatches = allMatches.filter(
    (match) =>
      new Date(match.date) > Date.now() &&
      new Date(match.date) > Date.now() + oneDay
  )

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '12px',
      }}
    >
      <PredictionGuidance />

      {new Date(groupMatches[groupMatches.length - 1].date).getTime() >
        Date.now() && (
        <Groups groups={groups} database={database} number={number} />
      )}

      {(new Date(groupMatches[groupMatches.length - 1].date).getTime() <
        Date.now() ||
        finishedMatches.length > 0) && (
        <FinishedMatches
          groups={groups}
          matches={finishedMatches}
          database={database}
          number={number}
        />
      )}

      {recentMatches.length > 0 && (
        <PredictionSeparator sectionTitle={'Recent'} />
      )}
      {recentMatches.map((match) => (
        <MatchDetails
          key={match.id}
          match={match}
          database={database}
          number={number}
        />
      ))}

      {todayMatches.length > 0 && (
        <PredictionSeparator sectionTitle={'Today'} />
      )}
      {todayMatches.map((match) => (
        <MatchDetails
          key={match.id}
          match={match}
          database={database}
          number={number}
        />
      ))}

      {upcomingMatches.length > 0 && (
        <PredictionSeparator sectionTitle={'Upcoming'} />
      )}
      {upcomingMatches.map((match) => (
        <MatchDetails
          key={match.id}
          match={match}
          database={database}
          number={number}
        />
      ))}
    </main>
  )
}

Matches.propTypes = {
  groups: PropTypes.object,
  matches: PropTypes.array.isRequired,
  database: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
}

export default Matches
