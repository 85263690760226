import React from 'react'
import constants from '../data/constants'
import stages from '../data/stages'

const headers = ['Stage', 'Points', 'Predictions', 'Total']
const body = Object.values(stages).sort((a, b) => a.number - b.number)
const bestScore = Object.values(stages).reduce((sum, stage) => {
  return sum + stage.points * stage.predictions
}, 0)
const totalMatches = Object.values(stages).reduce((sum, stage) => {
  return sum + stage.predictions
}, 0)

function MatchPredictionRules() {
  return (
    <section
      style={{
        width: '100%',
        maxWidth: '600px',
        textAlign: 'left',
        margin: '0 auto',
        marginTop: '24px',
        backgroundColor: constants.colors.cream,
        borderRadius: '12px',
        padding: '24px',
      }}
    >
      <strong>Getting points</strong>
      <p>
        For each stage, predict the group winners or the final result of a match
        (home team or away team). For every correct answer, you get points
        depending on the stage.
      </p>
      <br />

      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={header}
                style={{ textAlign: index === 0 ? 'left' : 'center' }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((stage) => (
            <tr key={stage.type}>
              <td style={{ textAlign: 'left' }}>{stage.name}</td>
              <td style={{ textAlign: 'center' }}>{stage.points}</td>
              <td style={{ textAlign: 'center' }}>{stage.predictions}</td>
              <td style={{ textAlign: 'center' }}>
                {stage.points * stage.predictions}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />

      <p>
        The best possible score is {bestScore} points from {totalMatches}{' '}
        predictions.
      </p>
    </section>
  )
}

export default MatchPredictionRules
