import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PredictionHeader from '../PredictionHeader'
import MatchResults from './MatchResults'
import MatchPrediction from './MatchPrediction'
import Predictions from '../Predictions'
import EditMatch from '../EditMatch/EditMatch'
import constants from '../../data/constants'
import stages from '../../data/stages'
import chooseMatchResult from '../../../../actions/chooseMatchResult'
import countries from '../../data/countries'
import { getParticipantNameFromNumber } from '../../../../utilities/lib'

function MatchDetails({ match, database, number }) {
  const [isEditing, setIsEditing] = useState(false)

  const isAdmin = constants.admin.includes(number)
  const hasStarted = new Date(match.date) < Date.now()

  const chooseOption = (option) => {
    const hasStarted = new Date(match.date) < Date.now()
    if (hasStarted) return

    if (!countries.find((c) => c.name === option)) return

    const participantName = getParticipantNameFromNumber(number)
    chooseMatchResult({ matchID: match.id, database, participantName, option })
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '600px',
        marginBottom: '12px',
        backgroundColor: constants.colors.cream,
        padding: '12px',
        borderRadius: '8px',
      }}
    >
      <PredictionHeader
        predictionID={`Match ${match.id}`}
        stage={stages[match.stage].name}
        group={match.group}
        date={match.date}
        isAdmin={isAdmin}
        isEditing={isEditing}
        toggleEditing={() => setIsEditing(!isEditing)}
      />

      {!hasStarted && !isEditing && (
        <MatchPrediction
          match={match}
          number={number}
          chooseOption={chooseOption}
        />
      )}

      {hasStarted && !isEditing && <MatchResults match={match} />}

      {isEditing && <EditMatch match={match} database={database} />}

      <Predictions
        predictions={match.predictions || {}}
        result={match.result}
        number={number}
        hasStarted={hasStarted}
      />
    </div>
  )
}

MatchDetails.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    home_team: PropTypes.string.isRequired,
    away_team: PropTypes.string.isRequired,
    home_score: PropTypes.number,
    away_score: PropTypes.number,
    result: PropTypes.string,
  }),
  database: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
}

export default MatchDetails
