import React from 'react'
import PropTypes from 'prop-types'
import countries from '../../data/countries'

function MatchResults({ match }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
      }}
    >
      <div style={{ width: '33.3333%', textAlign: 'center' }}>
        <img
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '24px',
            border: '1px solid lightgray',
          }}
          alt={match.home_team}
          src={
            countries.find((c) => c.name === match.home_team)
              ? countries.find((c) => c.name === match.home_team).flag
              : ''
          }
        />

        <br />
        <strong style={{ fontSize: '16px' }}>{match.home_team}</strong>
      </div>

      <div
        style={{
          width: '14%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: '36px' }}>
          {typeof match.home_score === 'number' ? match.home_score : ' '}
        </span>
      </div>

      <div
        style={{
          width: '5.3333%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: '36px' }}>-</span>
      </div>

      <div
        style={{
          width: '14%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: '36px' }}>
          {typeof match.away_score === 'number' ? match.away_score : ' '}
        </span>
      </div>

      <div style={{ width: '33.3333%', textAlign: 'center' }}>
        <img
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '24px',
            border: '1px solid lightgray',
          }}
          alt={match.away_team}
          src={
            countries.find((c) => c.name === match.away_team)
              ? countries.find((c) => c.name === match.away_team).flag
              : ''
          }
        />

        <br />
        <strong style={{ fontSize: '16px' }}>{match.away_team}</strong>
      </div>
    </div>
  )
}

MatchResults.propTypes = {
  match: PropTypes.shape({
    home_team: PropTypes.string.isRequired,
    away_team: PropTypes.string.isRequired,
    home_score: PropTypes.number,
    away_score: PropTypes.number,
  }),
}

export default MatchResults
