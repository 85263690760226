import constants from '../containers/current/data/constants'

const predictCountry = ({ country, participantName, database }) => {
  const prediction = {}
  prediction[participantName] = country

  database.ref(constants.COUNTRY_PREDICTIONS_URL).update(prediction)
}

export default predictCountry
