import constants from "./constants"

function sortAlphabetically(first, second) {
  switch (true) {
    case first > second:
      return 1
    case first < second:
      return -1
    default:
      return -1
  }
}

// const convertQatarDateToUTC = function (date) {
//   const [calendar, time] = date.split(' ')
//   const [month, day, year] = calendar.split('/')
//   const [hour, minutes] = time.split(':')
//   const UTCDate = new Date(Date.UTC(year, month - 1, day, hour - 3, minutes))
//   return UTCDate.toISOString()
// }

function hasTournamentStarted() {
  return new Date(constants.START_DATE) < Date.now()
}


export { sortAlphabetically, hasTournamentStarted }