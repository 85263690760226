import React from 'react'
import PropTypes from 'prop-types'
import CountdownTimer from './CountdownTimer'
import constants from '../data/constants'

function PredictionHeader({
  predictionID,
  stage,
  group = '',
  date,
  isAdmin,
  isEditing,
  toggleEditing,
}) {
  const hasStarted = new Date(date) < Date.now()

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '8px',
        marginBottom: '8px',
        borderBottom: `1px solid ${constants.colors.teal}`,
      }}
    >
      <strong style={{ width: '33.3333%' }}>{predictionID}</strong>
      <strong style={{ width: '33.3333%', textAlign: 'center' }}>
        {stage}
        {group && <span>&nbsp;{group}</span>}
      </strong>

      {!isAdmin && <CountdownTimer date={date} />}
      {!hasStarted && isAdmin && <CountdownTimer date={date} />}

      {isAdmin && (
        <span
          onClick={toggleEditing}
          style={{ width: '33.3333%', textAlign: 'right', cursor: 'pointer' }}
        >
          {isEditing ? 'Done' : 'Edit'}
        </span>
      )}
    </div>
  )
}

PredictionHeader.propTypes = {
  predictionID: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
  group: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
}

export default PredictionHeader
