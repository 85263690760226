import constants from '../containers/current/data/constants'

const chooseMatchResult = ({ matchID, database, participantName, option }) => {
  const prediction = {}
  prediction[participantName] = option

  database
    .ref(constants.MATCHES_URL)
    .child(matchID)
    .child('predictions')
    .update(prediction)
    .catch((error) => {
      window.alert(`There was an error saving your prediction: ${error}`)
    })
}

export default chooseMatchResult
