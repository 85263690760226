import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../data/constants'
import stages from '../../data/stages'
import { getParticipantNameFromNumber } from '../../../../utilities/lib'
import countries from '../../data/countries'

const placeholderImage =
  'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiFbM2yH5iA_4I8gHwoxQemDRO19yMTV7F5CyIltf-tCUOJ1BLFHuE-Cofa5jwrzpZnpzHSiR3PQZHsxAkKjencExYVKc3FtPICItBwVCDzE_buK0WMnRFRTRhSlAQQH-mXhkEGoZ1PCwl95a7X6Y135k8LIoUWN8vFTiiSvSaA4eoFbnQB2jiKWerV/s1000/uefa%20euro%202024%20sleeve%20badge%20%288%29.jpg'
function MatchPrediction({ match, number, chooseOption }) {
  const home = {
    name: match.home_team,

    flag: countries.find((c) => c.name === match.home_team)
      ? countries.find((c) => c.name === match.home_team).flag
      : placeholderImage,
  }

  const draw = {
    name: 'Draw',
    flag: 'https://www.symbols.com/images/symbol/1/5528_dash.png',
  }

  const away = {
    name: match.away_team,
    flag: countries.find((c) => c.name === match.away_team)
      ? countries.find((c) => c.name === match.away_team).flag
      : placeholderImage,
  }

  const options =
    match.stage === stages.group.type ? [home, draw, away] : [home, away]

  const selected =
    !!match.predictions &&
    number &&
    match.predictions[getParticipantNameFromNumber(number)]
      ? match.predictions[getParticipantNameFromNumber(number)]
      : null

  return (
    <div style={{ width: '100%' }}>
      {options.map((option) => (
        <button
          key={option.name}
          type="button"
          style={{
            width: `calc(100%/${options.length})`,
            textAlign: 'center',
            padding: '8px',
            backgroundColor:
              selected === option.name ? constants.colors.teal : 'transparent',
            cursor: 'pointer',
            borderRadius: '8px',
          }}
          onClick={() => chooseOption(option.name)}
        >
          <img
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '24px',
              border: '1px solid lightgray',
            }}
            alt={option.name}
            src={option.flag}
          />

          <br />
          <strong
            style={{
              fontSize: '16px',
              color: selected === option.name ? constants.colors.cream : '',
            }}
          >
            {option.name}
          </strong>
        </button>
      ))}
    </div>
  )
}

MatchPrediction.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number.isRequired,
    home_team: PropTypes.string.isRequired,
    away_team: PropTypes.string.isRequired,
  }),
  database: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
  chooseOption: PropTypes.func.isRequired,
}

export default MatchPrediction
