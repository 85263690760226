import React from 'react'
import constants from '../data/constants'
import participants from '../data/participants'

function WinnerPredictionRules() {
  const totalPrizeMoney = constants.buyIn.country * participants.length

  return (
    <section
      style={{
        width: '100%',
        maxWidth: '600px',
        textAlign: 'left',
        margin: '0 auto',
        marginBottom: '24px',
        backgroundColor: constants.colors.cream,
        borderRadius: '12px',
        padding: '24px',
      }}
    >
      <h2 style={{ color: constants.colors.red }}>
        Pick the winner of {constants.TOURNAMENT_NAME}
      </h2>
      <br />
      <strong>What you can win</strong>
      <p>
        All participants put in ${constants.buyIn.country} at the beginning, and
        the winner gets everything at the end (the prize money of $
        {totalPrizeMoney} is split equally if there is a tie).
      </p>
    </section>
  )
}

export default WinnerPredictionRules
