import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Groups from './Groups/Groups'
import MatchDetails from './Matches/MatchDetails'
import PredictionSeparator from './PredictionSeparator'
import constants from '../data/constants'

function FinishedMatches({ groups, matches, database, number }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <div style={{ width: '100%', maxWidth: '600px' }}>
        <PredictionSeparator sectionTitle={'Finished'}>
          <span
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ color: constants.colors.cream, cursor: 'pointer' }}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </span>
        </PredictionSeparator>
      </div>

      {isExpanded && (
        <>
          <Groups groups={groups} database={database} number={number} />

          {matches.map((match) => (
            <MatchDetails
              key={match.id}
              match={match}
              database={database}
              number={number}
            />
          ))}
        </>
      )}
    </>
  )
}

FinishedMatches.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  database: PropTypes.object.isRequired,
  number: PropTypes.string.isRequired,
}

export default FinishedMatches
