import React from 'react'
import PropTypes from 'prop-types'
import constants from './data/constants'
import { sortAlphabetically, hasTournamentStarted } from './data/functions'
import WinnerPredictionRules from './countries/WinnerPredictionRules'
import CountrySelection from './countries/CountrySelection'
import PotentialChampions from './countries/PotentialChampions'
import EditCountries from './countries/EditCountries'
import predictCountry from '../../actions/chooseCountry'
import { getParticipantNameFromNumber } from '../../utilities/lib'
import toggleCountryEliminatedStatus from '../../actions/toggleCountryEliminatedStatus'

function Countries({ database, number, countries, country_predictions }) {
  const options = Object.values(countries).sort((a, b) =>
    sortAlphabetically(a.name, b.name)
  )

  const selected =
    !!number &&
    !!country_predictions &&
    country_predictions[getParticipantNameFromNumber(number)]
      ? country_predictions[getParticipantNameFromNumber(number)]
      : null

  const chooseCountry = (country) => {
    if (hasTournamentStarted()) return

    const participantName = getParticipantNameFromNumber(number)
    predictCountry({ country, participantName, database })
  }

  const toggleEliminated = (country) => {
    toggleCountryEliminatedStatus({ country, database })
  }

  return (
    <main style={{ width: '100%', padding: '12px' }}>
      <WinnerPredictionRules />
      {!hasTournamentStarted() && (
        <CountrySelection
          options={options}
          chooseCountry={chooseCountry}
          selected={selected}
          country_predictions={country_predictions}
        />
      )}
      {hasTournamentStarted() && (
        <PotentialChampions
          options={options}
          selected={selected}
          country_predictions={country_predictions}
        />
      )}

      {constants.admin.includes(number) && (
        <EditCountries options={options} toggleEliminated={toggleEliminated} />
      )}
    </main>
  )
}

Countries.propTypes = {
  countries: PropTypes.array,
  number: PropTypes.string.isRequired,
  database: PropTypes.object.isRequired,
}

export default Countries
