import React from 'react'
import constants from '../data/constants'

function PredictionGuidance() {
  return (
    <section
      style={{
        width: '100%',
        maxWidth: '600px',
        textAlign: 'left',
        margin: '0 auto',
        marginBottom: '24px',
        backgroundColor: constants.colors.cream,
        borderRadius: '12px',
        padding: '24px',
      }}
    >
      <h2 style={{ color: constants.colors.red }}>
        Select the correct result for each match
      </h2>
      <br />
      <strong>Select a team</strong>
      <p>
        You can change your mind for the group winners until the first game for
        each group starts, or until the game for subsequent stages is underway.
        Everyone's prediction will be made visible at kick off.
      </p>
    </section>
  )
}

export default PredictionGuidance
