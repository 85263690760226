import React from 'react'
import PropTypes from 'prop-types'
import participants from '../data/participants'
import constants from '../data/constants'

function PotentialWinnings({ activeParticipants, isSelected, displayAmount }) {
  const totalPrizeMoney = constants.buyIn.country * participants.length

  const formatWinnings = () => {
    const amount = totalPrizeMoney / activeParticipants.length
    return amount % 1 === 0 ? amount : amount.toFixed(2)
  }

  return (
    <div
      style={{
        marginBottom: '24px',
        backgroundColor: isSelected
          ? constants.colors.teal
          : constants.colors.cream,
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      }}
    >
      {activeParticipants.map((p) => (
        <div
          key={p}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
            paddingRight: '32px',
          }}
        >
          <strong>{p}</strong>
          {displayAmount && <strong>${formatWinnings()}</strong>}
        </div>
      ))}
    </div>
  )
}

PotentialWinnings.propTypes = {
  activeParticipants: PropTypes.array,
  isSelected: PropTypes.bool.isRequired,
  displayAmount: PropTypes.bool,
}

export default PotentialWinnings
