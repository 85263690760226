import constants from '../containers/current/data/constants'

const chooseGroupResult = ({ groupID, database, participantName, option }) => {
  const prediction = {}
  prediction[participantName] = option

  database
    .ref(constants.GROUPS_URL)
    .child(groupID)
    .child('predictions')
    .update(prediction)
    .catch((error) => {
      window.alert(`There was an error saving your prediction: ${error}`)
    })
}

export default chooseGroupResult
