import React from 'react'
import constants from '../data/constants'
import participants from '../data/participants'

function TableGuidance() {
  const totalPrize = constants.buyIn.match * participants.length

  return (
    <section style={{ width: "100%", maxWidth: '600px', textAlign: "left", margin: "0 auto", marginBottom: '24px', backgroundColor: constants.colors.cream, borderRadius: '12px', padding: '24px' }}>
      <h2 style={{color: constants.colors.red}}>Finish with the most points</h2>
      <br />
      <strong>What you can win</strong>
      <p>
        All participants put in ${constants.buyIn.match} at the beginning, and the winner gets
        everything at the end (the prize money of ${totalPrize} is split equally if there is
        a tie).
      </p>
    </section>
  )
}

export default TableGuidance
