const participants = [
  {
    name: 'Jonathan and Cara',
    numbers: ['+6587427184', '+6590485907', 'jonathanlouisng@gmail.com'],
  },
  {
    name: 'Max',
    numbers: ['+6594517971'],
  },
  {
    name: 'Rae',
    numbers: ['+61431536778'],
  },
  {
    name: 'Linus',
    numbers: ['+61423464102'],
  },
  // {
  //   name: 'Cara',
  //   numbers: ['+6590485907']
  // },
  {
    name: 'Nicholas',
    numbers: ['+6590039948'],
  },
  {
    name: 'Nicole',
    numbers: ['+6591852822', 'spaceshipshimmy@gmail.com'],
  },
  {
    name: 'Marc and Kellyn',
    numbers: ['+6597918284', '+6597377672'],
  },
  {
    name: 'Ian',
    numbers: ['+6596678570'],
  },
  // {
  //   name: 'Ryan',
  //   numbers: '+447561716587'
  // },
  {
    name: 'Bryan',
    numbers: ['+6592391840', '+61478089604'],
  },
  {
    name: 'Madeleine',
    numbers: ['+6597521154'],
  },
  // {
  //   name: 'Kellyn',
  //   numbers: '+6597377672'
  // },
]

export default participants
