import React from 'react'
import PropTypes from 'prop-types'
import CountryCard from './CountryCard'
import PredictionSeparator from '../matches/PredictionSeparator'

function EditCountries({ options, toggleEliminated }) {
  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '32px auto' }}>
      <PredictionSeparator sectionTitle={'Edit Countries'} />
      {options
        .filter((c) => !c.isEliminated)
        .concat(options.filter((c) => c.isEliminated))
        .map((country) => (
          <button
            key={country.name}
            type="button"
            onClick={() => toggleEliminated(country)}
            style={{ width: '100%', maxWidth: '600px', cursor: 'pointer' }}
          >
            <CountryCard country={country} isSelected={false} count={0} />
          </button>
        ))}
    </div>
  )
}

EditCountries.propTypes = {
  options: PropTypes.array,
  toggleEliminated: PropTypes.func.isRequired,
}

export default EditCountries
